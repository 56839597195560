import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { RequestADemoPrimaryCta } from "components/common/request-a-demo-cta/primary"

export const Hero = () => {
	const { t } = useTranslation()

	return (
		<section className="flex flex-col gap-6">
			<h1 className="text-4xl gradient-text max-w-max sm:text-5xl md:text-6xl">
				{t("products-data-management-page.hero.heading")}
			</h1>
			<p className="paragraph">
				{t("products-data-management-page.hero.desc.1")}
			</p>
			<p className="paragraph">
				{t("products-data-management-page.hero.desc.2")}
			</p>

			<div className="flex flex-col sm:flex-row gap-4">
				<RequestADemoPrimaryCta />
			</div>
		</section>
	)
}
