import React from "react"
import { graphql } from "gatsby"
import { Container } from "components/library"
import { MiniNavbar } from "components/common/mini-navbar"
import { Faqs } from "components/common/faqs"
import { CtaBlock } from "components/common/cta-block"
import { Hero } from "components/pages/products/data-management/hero"
import { Benefits } from "components/pages/products/data-management/benefits"
import { ManageAtScale } from "components/pages/products/data-management/manage-at-scale"
import { Features } from "components/pages/products/data-management/features"

const DataManagement = ({ data: { allFaq: { nodes } } }: any) => {
	return (
		<main>
			<Container className="flex flex-col gap-20 sm:gap-32 pt-16 sm:pt-12">
				<div className="flex flex-col gap-8 sm:gap-12">
					<MiniNavbar
						className="hidden sm:flex"
						title="common.words.data-management"
						links={[
							{
								label: "common.words.benefits",
								path: "#benefits",
							},
							{ label: "common.words.features", path: "#features" },
						]}
					/>
					<Hero />
				</div>
			</Container>

			<Benefits />

			<Container className="flex flex-col gap-20 pb-32">
				<ManageAtScale />
				<Features />
			</Container>

			<Container>
				<Faqs faqs={nodes} />
			</Container>

			<CtaBlock />
		</main>
	)
}

export default DataManagement

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}

		allFaq(filter: {categories: {elemMatch: {name: {eq: "Website Data Management"}}}}) {
      nodes {
        id
        question
        answer
      }
    }
	}
`
