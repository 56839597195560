import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const Pattern3Illustration: Illustration = ({
	className = "",
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 122 123"
			className={mergeClasses({ classes: ["w-40", className] })}
			{...props}
		>
			<circle cx="61" cy="61.589" r="61" fill="#DEE9FD"></circle>
			<path
				fill="url(#paint0_linear_6564_41038)"
				d="M60.83 37.394l23.414 7.805v17.17l-23.415-7.804V37.394z"
			></path>
			<path
				fill="url(#paint1_linear_6564_41038)"
				d="M107.658 37.394l-23.414 7.805v17.17l23.414-7.804V37.394z"
			></path>
			<path
				fill="url(#paint2_linear_6564_41038)"
				d="M60.83 37.394l23.414 7.805 23.414-7.805-23.414-7.805-23.415 7.805z"
			></path>
			<path
				fill="url(#paint3_linear_6564_41038)"
				d="M37.414 53.003l23.415 7.805V77.98l-23.415-7.805v-17.17z"
			></path>
			<path
				fill="url(#paint4_linear_6564_41038)"
				d="M84.243 53.003L60.83 60.808V77.98l23.414-7.805v-17.17z"
			></path>
			<path
				fill="url(#paint5_linear_6564_41038)"
				d="M37.414 53.003l23.415 7.805 23.414-7.805L60.83 45.2l-23.415 7.804z"
			></path>
			<path
				fill="url(#paint6_linear_6564_41038)"
				d="M14 68.613l23.415 7.805v17.17L14 85.785v-17.17z"
			></path>
			<path
				fill="url(#paint7_linear_6564_41038)"
				d="M60.83 68.613l-23.416 7.805v17.17l23.415-7.804v-17.17z"
			></path>
			<path
				fill="url(#paint8_linear_6564_41038)"
				d="M14 68.613l23.415 7.805 23.414-7.805-23.414-7.805L14 68.613z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_6564_41038"
					x1="72.536"
					x2="72.536"
					y1="45.199"
					y2="62.369"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7BC598"></stop>
					<stop offset="1" stopColor="#9CF1BE" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_6564_41038"
					x1="95.951"
					x2="95.951"
					y1="45.199"
					y2="62.369"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7BC598"></stop>
					<stop offset="1" stopColor="#9CF1BE" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint2_linear_6564_41038"
					x1="75.268"
					x2="109.61"
					y1="43.247"
					y2="31.93"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7BC598"></stop>
					<stop offset="1" stopColor="#9CF1BE"></stop>
				</linearGradient>
				<linearGradient
					id="paint3_linear_6564_41038"
					x1="49.121"
					x2="49.121"
					y1="60.808"
					y2="77.979"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F39244"></stop>
					<stop offset="1" stopColor="#FAD8BD" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint4_linear_6564_41038"
					x1="72.536"
					x2="72.536"
					y1="60.808"
					y2="77.979"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F5B581"></stop>
					<stop offset="1" stopColor="#FAD8BD" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint5_linear_6564_41038"
					x1="51.853"
					x2="86.195"
					y1="58.857"
					y2="47.54"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F39244"></stop>
					<stop offset="1" stopColor="#FAD8BD"></stop>
				</linearGradient>
				<linearGradient
					id="paint6_linear_6564_41038"
					x1="25.707"
					x2="25.707"
					y1="76.418"
					y2="93.589"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#CCDBFB" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint7_linear_6564_41038"
					x1="49.122"
					x2="49.122"
					y1="76.418"
					y2="93.589"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#5F85EB"></stop>
					<stop offset="1" stopColor="#DEE9FD" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint8_linear_6564_41038"
					x1="28.439"
					x2="62.781"
					y1="74.467"
					y2="63.15"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#A6BFF7"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
