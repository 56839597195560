import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { FeaturesCardProps, FeaturesCard } from "./features-card"
import { Pattern6Illustration } from "components/library/illustrations/patterns/pattern6";
import { Pattern7Illustration } from "components/library/illustrations/patterns/pattern7";
import { Pattern9Illustration } from "components/library/illustrations/patterns/pattern9";
import { Pattern4Illustration } from "components/library/illustrations/patterns/pattern4";
import { Pattern8Illustration } from "components/library/illustrations/patterns/pattern8";
import { Pattern1Illustration } from "components/library/illustrations/patterns/pattern1";
import { Pattern3Illustration } from "components/library/illustrations/patterns/pattern3";

const cards: FeaturesCardProps[] = [
	{
		title: "products-data-management-page.features.cards.1.title",
		desc: "products-data-management-page.features.cards.1.desc",
		icon: Pattern6Illustration,
	},
	{
		title: "products-data-management-page.features.cards.2.title",
		desc: "products-data-management-page.features.cards.2.desc",
		icon: Pattern7Illustration,
	},
	{
		title: "products-data-management-page.features.cards.3.title",
		desc: "products-data-management-page.features.cards.3.desc",
		icon: Pattern9Illustration,
	},
	{
		title: "products-data-management-page.features.cards.4.title",
		desc: "products-data-management-page.features.cards.4.desc",
		icon: Pattern4Illustration,
	},
	{
		title: "products-data-management-page.features.cards.5.title",
		desc: "products-data-management-page.features.cards.5.desc",
		icon: Pattern8Illustration,
	},
	{
		title: "products-data-management-page.features.cards.6.title",
		desc: "products-data-management-page.features.cards.6.desc",
		icon: Pattern1Illustration,
	},
]

export const Features = () => {
	const { t } = useTranslation()

	return (
		<section
			id="features"
			className="flex scroll-top-margin sm:items-center gap-8 sm:gap-12 flex-col"
		>
			<div className="flex flex-col items-center text-center gap-8">
				<Pattern3Illustration className="w-20 sm:w-32" />
				<h2 className="text-2xl sm:text-3xl text-theme-blue">
					{t("products-data-management-page.features.heading")}
				</h2>
			</div>

			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
				{cards.map((card) => {
					return (
						<FeaturesCard
							key={card.title}
							desc={card.desc}
							title={card.title}
							icon={card.icon}
						/>
					)
				})}
			</div>
		</section>
	)
}
