import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const Pattern8Illustration: Illustration = ({
	className = "",
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 73 46"
			className={mergeClasses({ classes: ["w-40", className] })}
			{...props}
		>
			<rect
				width="44.4"
				height="27"
				x="13.5"
				y="2.089"
				stroke="url(#paint0_linear_6564_41265)"
				strokeWidth="3"
				rx="2.5"
			></rect>
			<path
				fill="url(#paint1_linear_6564_41265)"
				d="M29.073 13.4c4.975.808 7.703.797 12.463 0v5.766c-4.802 3.209-7.546 3.18-12.463 0V13.4z"
			></path>
			<path
				fill="url(#paint2_linear_6564_41265)"
				d="M35.438 8.892L45 13.152l-9.563 4.259-9.562-4.26 9.563-4.259z"
			></path>
			<path
				fill="url(#paint3_linear_6564_41265)"
				d="M11.823 33.585a2 2 0 011.425-.596h46.104a2 2 0 011.425.596l8.47 8.6c1.246 1.265.35 3.404-1.424 3.404H4.777c-1.774 0-2.67-2.14-1.425-3.404l8.47-8.6z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_6564_41265"
					x1="20.926"
					x2="36.883"
					y1="1.189"
					y2="33.022"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#DEE9FD" stopOpacity="0.52"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_6564_41265"
					x1="35.305"
					x2="35.305"
					y1="13.4"
					y2="21.562"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F8CCA9" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#F39244"></stop>
				</linearGradient>
				<linearGradient
					id="paint2_linear_6564_41265"
					x1="35.172"
					x2="35.438"
					y1="9.555"
					y2="17.378"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#9CF1BE" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#7BC598"></stop>
				</linearGradient>
				<linearGradient
					id="paint3_linear_6564_41265"
					x1="34"
					x2="36"
					y1="16.589"
					y2="45.589"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#3C67F0"></stop>
					<stop offset="0.573" stopColor="#7E9EF4" stopOpacity="0.18"></stop>
					<stop offset="1" stopColor="#A6BFF7" stopOpacity="0"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
