import React from "react"
import Pattern from "images/icon_why_polymerize.png"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { ImportDataIllustration } from "components/library/illustrations/lab/import-data";

export const ManageAtScale = () => {
	const { t } = useTranslation()

	return (
		<section className="flex flex-col gap-4 items-center text-center">
			<img
				src={Pattern}
				alt="manage seamlessly at scale"
				className="w-20 sm:w-32"
			/>

			<h2 className="text-2xl sm:text-3xl text-theme-blue">
				{t("products-data-management-page.manage-at-scale.title")}
			</h2>
			<p className="text-lg sm:text-xl">
				{t("products-data-management-page.manage-at-scale.desc")}
			</p>

			<ImportDataIllustration className="w-full sm:w-[70%]" />
		</section>
	)
}
