import React, { ComponentPropsWithoutRef } from "react"
import { mergeClasses } from 'utils';

type P = ComponentPropsWithoutRef<"svg">

export const DataSecurityIllustration = ({ className = '', ...props }: P) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 54 64"
            className={mergeClasses({ classes: ['w-20', className] })}
            {...props}
        >
            <path
                fill="url(#paint0_linear_5800_38336)"
                d="M52.338 11.332a84.094 84.094 0 01-12.715-4.514A86.43 86.43 0 0127.946.653L26.889 0l-1.037.672a86.432 86.432 0 01-11.678 6.165 84.097 84.097 0 01-12.733 4.495L0 11.697v16.018c0 25.717 25.985 35.34 26.235 35.435l.653.231.653-.23c.269 0 26.236-9.7 26.236-35.436V11.697l-1.44-.365z"
            ></path>
            <path
                fill="url(#paint1_linear_5800_38336)"
                d="M34.742 43.445H19.035a1.219 1.219 0 01-1.218-1.218V30.605c0-.673.545-1.222 1.218-1.222h1.476v-1.877a6.386 6.386 0 016.377-6.377 6.386 6.386 0 016.378 6.377v1.877h1.475c.673 0 1.218.55 1.218 1.222v11.622a1.216 1.216 0 01-1.218 1.218h.001zm-7.055-6.427c1.446-.817.866-3.025-.798-3.025-1.659.005-2.238 2.204-.797 3.025v1.574c0 1.06 1.594 1.06 1.594 0v-1.574zm-3.516-7.636h5.442v-1.877c0-1.5-1.223-2.718-2.724-2.718a2.719 2.719 0 00-2.718 2.718v1.877zm-6.73-1.728h-3.787c-.619 0-1.114-.5-1.114-1.114 0-.614.495-1.114 1.114-1.114h3.788c.614 0 1.114.5 1.114 1.114 0 .614-.5 1.114-1.114 1.114zm2.67-5.962c-.258 0-.508-.09-.704-.257l-3-2.466a1.11 1.11 0 01.186-1.841 1.109 1.109 0 011.23.123l3 2.466c.808.664.332 1.976-.712 1.97v.005zm6.778-2.625a1.116 1.116 0 01-1.114-1.114v-3.818c0-.614.5-1.114 1.114-1.114.614 0 1.114.5 1.114 1.114v3.818c0 .614-.5 1.114-1.114 1.114zm6.779 2.625c-1.05 0-1.52-1.311-.713-1.975l3-2.466c1.149-.942 2.56.777 1.417 1.723l-3 2.466a1.102 1.102 0 01-.704.253zm6.457 5.962h-3.823c-.619 0-1.114-.5-1.114-1.114 0-.614.495-1.114 1.114-1.114h3.823c.619 0 1.114.5 1.114 1.114 0 .614-.495 1.114-1.114 1.114z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_5800_38336"
                    x1="26.889"
                    x2="26.889"
                    y1="0"
                    y2="63.381"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3C67F0"></stop>
                    <stop offset="0.536" stopColor="#3C67F0" stopOpacity="0.444"></stop>
                    <stop offset="1" stopColor="#3C67F0" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_5800_38336"
                    x1="26.89"
                    x2="26.89"
                    y1="13.021"
                    y2="43.445"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F39244"></stop>
                    <stop offset="0.51" stopColor="#F8CCA9"></stop>
                    <stop offset="0.671" stopColor="#F8CCA9"></stop>
                    <stop offset="1" stopColor="#fff"></stop>
                    <stop offset="1" stopColor="#F39244" stopOpacity="0.522"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}
