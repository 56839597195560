import React from "react"
import { Illustration } from "types/components"
import { TranslationKey } from "types/translation"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

type P = {
	title: TranslationKey
	desc: TranslationKey
	icon: Illustration
}

export type FeaturesCardProps = P

export const FeaturesCard = ({ desc, icon, title }: P) => {
	const Icon = icon
	const { t } = useTranslation()

	return (
        <div className="flex items-stretch">
            <div className="w-2 h-[80%] mt-auto bg-neutral-200">
                <div className="h-10 bg-theme-blue w-full mt-9"></div>
            </div>

            <div className="flex flex-col gap-4 p-6">
                <Icon className="w-12 sm:w-14" />
                <p className="text-2xl sm:text-3xl">{t(title) as string}</p>
                <p>
                    <Trans
                        i18nKey={`${desc}`}
                        components={{
                            anchor: <a className="anchor" />,
                        }}
                    />
                </p>
            </div>
        </div>
    );
}
