import React from "react"
import { Illustration } from "types/components"
import { TranslationKey } from "types/translation"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

type P = {
	title: TranslationKey
	desc: TranslationKey
	icon: Illustration
}

export type BenefitsCardProps = P

export const BenefitsCard = ({ desc, icon, title }: P) => {
	const Icon = icon
	const { t } = useTranslation()

	return (
        <div className="flex flex-col gap-4 bg-white p-6 rounded-sm shadow-md">
            <Icon className="w-12 sm:w-16" />
            <p className="text-2xl sm:text-3xl">{t(title) as string}</p>
            <p>
                <Trans
                    i18nKey={`${desc}`}
                    components={{
                        anchor: <a className="anchor" />,
                    }}
                />
            </p>
        </div>
    );
}
