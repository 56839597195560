import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const Pattern5Illustration: Illustration = ({
	className = "",
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			className={mergeClasses({ classes: ["w-20", className] })}
			viewBox="0 0 79 73"
			{...props}
		>
			<path
				fill="url(#paint0_linear_6564_41103)"
				d="M39.059 0l19.975 10.266L39.356 20.53 19.678 10.266 39.06 0z"
			></path>
			<path
				fill="url(#paint1_linear_6564_41103)"
				d="M19.678 10.266L39.356 20.53v20.532L19.678 30.797V10.266z"
			></path>
			<path
				fill="url(#paint2_linear_6564_41103)"
				d="M39.356 41.063l19.677-10.266V10.266L39.356 20.53v20.532z"
			></path>
			<path
				fill="url(#paint3_linear_6564_41103)"
				d="M19.885 30.797l19.47 10.266-19.47 10.835L0 41.348l19.885-10.55z"
			></path>
			<path
				fill="url(#paint4_linear_6564_41103)"
				d="M.316 41.347l19.57 10.398V72.54L.315 62.142V41.347z"
			></path>
			<path
				fill="url(#paint5_linear_6564_41103)"
				d="M19.885 72.54l19.57-10.398-.1-21.08-19.47 10.683V72.54z"
			></path>
			<path
				fill="url(#paint6_linear_6564_41103)"
				d="M59.033 30.797l19.778 10.55-19.57 10.55-19.885-10.834 19.677-10.266z"
			></path>
			<path
				fill="url(#paint7_linear_6564_41103)"
				d="M39.356 41.063L59.24 51.745V72.54L39.356 62.327V41.064z"
			></path>
			<path
				fill="url(#paint8_linear_6564_41103)"
				d="M59.241 72.54l19.57-10.398V41.347l-19.57 10.398V72.54z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_6564_41103"
					x1="26.118"
					x2="56.789"
					y1="15.596"
					y2="11.153"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#3C67F0"></stop>
					<stop offset="1" stopColor="#A6BFF7"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_6564_41103"
					x1="32"
					x2="25.994"
					y1="11.5"
					y2="38.095"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1B55CE"></stop>
					<stop offset="1" stopColor="#DEE9FD" stopOpacity="0.89"></stop>
				</linearGradient>
				<linearGradient
					id="paint2_linear_6564_41103"
					x1="46.5"
					x2="56.544"
					y1="13.5"
					y2="44.716"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#5F85EB"></stop>
					<stop offset="1" stopColor="#F3F6FA" stopOpacity="0.35"></stop>
				</linearGradient>
				<linearGradient
					id="paint3_linear_6564_41103"
					x1="6.944"
					x2="37.615"
					y1="46.393"
					y2="41.95"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#21AB57"></stop>
					<stop offset="1" stopColor="#7BC598"></stop>
				</linearGradient>
				<linearGradient
					id="paint4_linear_6564_41103"
					x1="13.5"
					x2="7.134"
					y1="47.5"
					y2="69.288"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7BC598"></stop>
					<stop offset="0.677" stopColor="#9CF1BE" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint5_linear_6564_41103"
					x1="27.5"
					x2="38.53"
					y1="48.5"
					y2="78.838"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7BC598"></stop>
					<stop offset="0.516" stopColor="#9CF1BE" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint6_linear_6564_41103"
					x1="46.3"
					x2="76.971"
					y1="46.393"
					y2="41.95"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F4A362"></stop>
					<stop offset="1" stopColor="#FCEADC"></stop>
				</linearGradient>
				<linearGradient
					id="paint7_linear_6564_41103"
					x1="50.5"
					x2="46.07"
					y1="45"
					y2="69.579"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F39244"></stop>
					<stop offset="0.743" stopColor="#FCEADC" stopOpacity="0.07"></stop>
				</linearGradient>
				<linearGradient
					id="paint8_linear_6564_41103"
					x1="66"
					x2="75.151"
					y1="43.5"
					y2="66.875"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F7C49C"></stop>
					<stop offset="1" stopColor="#FCEADC" stopOpacity="0"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
