import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const Pattern6Illustration: Illustration = ({
	className = "",
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 81 89"
			className={mergeClasses({ classes: ["w-20", className] })}
			{...props}
		>
			<path
				fill="url(#paint0_linear_6564_41211)"
				d="M77.746 46.74c-8.7-.926-12.958-4.72-14.81-6.942a4.257 4.257 0 00-3.331-1.573 4.257 4.257 0 00-3.332 1.573c-.555.648-1.296 1.389-2.221 2.222-2.777-5.369-7.775-9.348-13.79-10.644 3.609-3.332 5.922-8.052 5.922-13.328C46.184 8.145 38.133 0 28.137 0c-9.904 0-18.049 8.052-18.049 18.048 0 5.276 2.314 9.996 5.924 13.328C6.849 33.412 0 41.556 0 51.276v10.643a4.246 4.246 0 004.257 4.257h35.449c2.776 13.143 18.14 21.195 18.788 21.566.37.185.74.277 1.203.277.37 0 .834-.092 1.204-.278.833-.37 19.251-10.088 19.251-25.637-.093-15.364-.093-15.364-2.406-15.364zM15.27 18.14c0-7.034 5.739-12.772 12.773-12.772 7.127-.092 12.865 5.646 12.865 12.773a12.739 12.739 0 01-12.772 12.772c-7.127 0-12.866-5.738-12.866-12.772zM5.091 51.369c0-8.33 6.848-15.18 15.178-15.18h15.457a15.03 15.03 0 0113.605 8.608c-2.128.926-4.72 1.666-7.96 1.944-1.295.093-2.313 1.296-2.313 2.591v11.662H5.09v-9.625zm69.785 10.736c0 10.366-11.476 18.048-15.271 20.27-3.795-2.222-15.271-9.904-15.271-20.27V51.645c8.144-1.388 12.864-5.09 15.27-7.682 2.407 2.592 7.128 6.386 15.272 7.682v10.459z"
			></path>
			<path
				fill="url(#paint1_linear_6564_41211)"
				d="M54.27 60.878a2.626 2.626 0 00-3.702 0 2.626 2.626 0 000 3.702l4.813 4.813c.555.555 1.203.74 1.85.74.649 0 1.297-.277 1.852-.74l9.163-9.163a2.626 2.626 0 000-3.702 2.625 2.625 0 00-3.702 0l-7.312 7.312-2.962-2.962z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_6564_41211"
					x1="40.076"
					x2="40.076"
					y1="0"
					y2="88.019"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#9CF1BE"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_6564_41211"
					x1="52.5"
					x2="70"
					y1="66"
					y2="59.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F39244"></stop>
					<stop offset="1" stopColor="#F8CCA9" stopOpacity="0"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
