import React from "react"
import { useTranslation } from "react-i18next"
import {
	Container,
} from "components/library"
import { BenefitsCard, BenefitsCardProps } from "./benefits-card"
import { Pattern5Illustration } from "components/library/illustrations/patterns/pattern5";
import { Pattern4Illustration } from "components/library/illustrations/patterns/pattern4";
import { Pattern6Illustration } from "components/library/illustrations/patterns/pattern6";
import { Pattern2Illustration } from "components/library/illustrations/patterns/pattern2";
import {
	DataSecurityIllustration
} from "components/library/illustrations/feature-capabilities/data-security-illustration";

const cards: BenefitsCardProps[] = [
	{
		title: "products-data-management-page.benefits.cards.1.title",
		desc: "products-data-management-page.benefits.cards.1.desc",
		icon: Pattern5Illustration,
	},
	{
		title: "products-data-management-page.benefits.cards.2.title",
		desc: "products-data-management-page.benefits.cards.2.desc",
		icon: Pattern4Illustration,
	},
	{
		title: "products-data-management-page.benefits.cards.3.title",
		desc: "products-data-management-page.benefits.cards.3.desc",
		icon: DataSecurityIllustration,
	},
	{
		title: "products-data-management-page.benefits.cards.4.title",
		desc: "products-data-management-page.benefits.cards.4.desc",
		icon: Pattern6Illustration,
	},
]

export const Benefits = () => {
	const { t } = useTranslation()

	return (
		<section
			id="benefits"
			className="flex flex-col gap-6 relative bg-wave-bg py-0 sm:py-40 bg-no-repeat bg-cover"
		>
			<Container className="flex flex-col gap-8 sm:gap-12 py-20 sm:py-20 items-center">
				<Pattern2Illustration className="w-20 sm:w-32" />

				<h2 className="text-white text-2xl sm:text-3xl text-center">
					{t("products-data-management-page.benefits.heading")}
				</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
					{cards.map((card) => {
						return (
							<BenefitsCard
								key={card.title}
								desc={card.desc}
								title={card.title}
								icon={card.icon}
							/>
						)
					})}
				</div>
			</Container>
		</section>
	)
}
