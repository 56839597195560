import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const Pattern7Illustration: Illustration = ({
	className = "",
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			className={mergeClasses({ classes: ["w-20", className] })}
			viewBox="0 0 100 100"
			{...props}
		>
			<path
				fill="url(#paint0_linear_34_83)"
				d="M9.44 68.149a3.704 3.704 0 003.21.68l.95-.236V90.6a1.4 1.4 0 001.4 1.4h14.041a1.398 1.398 0 001.4-1.4v-16.8h4.16a1.4 1.4 0 001.4-1.4V59.368a7.957 7.957 0 00-7.591-7.961 6.996 6.996 0 00-4.792-9.625 7.06 7.06 0 00-5.984 1.347A6.97 6.97 0 0015 48.6c0 .965.204 1.92.597 2.801a7.768 7.768 0 00-7.597 7.757v6.043a3.715 3.715 0 001.438 2.948zm1.36-8.99a4.964 4.964 0 014.96-4.96h2.572a1.4 1.4 0 00.922-2.453 4.137 4.137 0 01-1.453-3.146 4.199 4.199 0 116.946 3.146 1.4 1.4 0 00.922 2.454h2.411a5.15 5.15 0 015.12 5.168v11.631h-2.8V59.8h-2.8v12.6h.041v16.8h-4.24v-18.2h-2.8v18.2h-4.2V67.808l7.442-2.481a1.398 1.398 0 00.885-1.77l-1.4-4.2a1.397 1.397 0 00-1.557-.938l-8.4 1.4.46 2.761 7.22-1.203.58 1.737-7.024 2.342-2.636.659a.943.943 0 01-1.17-.914V59.16z"
			></path>
			<path
				fill="url(#paint1_linear_34_83)"
				fillRule="evenodd"
				d="M73.799 10.801c-.9.003-1.8.081-2.688.232l-.487-2.756a18.367 18.367 0 016.315-.005l-.48 2.759a15.44 15.44 0 00-2.66-.23zm13.926 3.68a18.258 18.258 0 00-4.843-4.054l-1.4 2.425a15.446 15.446 0 014.102 3.434l2.14-1.805zm-3.31 6.87a1.401 1.401 0 01.034 2.25l-5.281 4.032 2.025 6.555a1.4 1.4 0 01-2.188 1.525L73.8 31.736l-5.206 3.977a1.4 1.4 0 01-2.188-1.526l2.025-6.554L63.15 23.6a1.4 1.4 0 01.85-2.513h6.455l2.008-6.499a1.399 1.399 0 012.674 0l2.008 6.5H83.6c.293 0 .578.091.816.262zm-8.206 5.336c.087-.272.255-.512.483-.686l2.769-2.114h-3.349a1.4 1.4 0 01-1.337-.987l-.976-3.156-.976 3.156a1.4 1.4 0 01-1.337.988h-3.348L70.906 26a1.4 1.4 0 01.487 1.526l-1.02 3.303 2.576-1.969a1.403 1.403 0 011.7 0l2.576 1.969-1.02-3.303a1.399 1.399 0 01.004-.84zm12.076 4.751l2.633.952a18.104 18.104 0 01-3.152 5.478l-2.147-1.797a15.343 15.343 0 002.666-4.633zM58.4 26.201l-2.8.068V26.2a18.128 18.128 0 011.093-6.227l2.63.96a15.322 15.322 0 00-.923 5.267zm3.636 9.937a15.357 15.357 0 01-2.693-4.618l-2.628.97a18.185 18.185 0 003.183 5.456l2.138-1.808zm2.654-25.694l1.403 2.423c-1.555.9-2.94 2.064-4.095 3.441l-2.144-1.802c1.363-1.625 3-3 4.836-4.062zm26.204 9.5l-2.628.963c.616 1.687.932 3.468.934 5.264l2.8-.006a18.102 18.102 0 00-1.106-6.22zm-7.953 21.997a18.052 18.052 0 01-5.932 2.179l-.492-2.756a15.295 15.295 0 005.016-1.843l1.408 2.42zm-16.8-2.376l-1.394 2.428a18.03 18.03 0 005.949 2.143l.473-2.759a15.26 15.26 0 01-5.027-1.812z"
				clipRule="evenodd"
			></path>
			<path
				fill="url(#paint2_linear_34_83)"
				fillRule="evenodd"
				d="M10.8 8.001h2.8v2.8h-2.8v2.8H8.002v-2.8h2.8v-2.8zm2.8 5.6h-2.8v2.8h2.8v-2.8zm0-2.8h2.8v2.8h-2.8v-2.8z"
				clipRule="evenodd"
			></path>
			<path
				fill="url(#paint3_linear_34_83)"
				fillRule="evenodd"
				d="M31.8 23.4h2.8v2.8h-2.8V29H29v-2.8h2.8v-2.8zm2.8 5.6h-2.8v2.8h2.8V29zm0-2.8h2.8V29h-2.8v-2.8z"
				clipRule="evenodd"
			></path>
			<path
				fill="url(#paint4_linear_34_83)"
				fillRule="evenodd"
				d="M86.399 83.6h2.8v2.799h-2.8v2.8h-2.8v-2.8h2.8v-2.8zm2.8 5.599h-2.8v2.8h2.8v-2.8zm0-2.8h2.8v2.8h-2.8v-2.8z"
				clipRule="evenodd"
			></path>
			<path
				fill="url(#paint5_linear_34_83)"
				fillRule="evenodd"
				d="M65.4 61.2h2.8V64h-2.8v-2.8zM62.6 64h2.8v2.8h-2.8V64zm5.6 2.8h-2.8v2.8h2.8v-2.8H71V64h-2.8v2.8z"
				clipRule="evenodd"
			></path>
			<path
				fill="url(#paint6_linear_34_83)"
				fillRule="evenodd"
				d="M45.8 76.6h2.8v2.8h-2.8v2.799H43v-2.8h2.8v-2.8zm2.8 5.6h-2.8V85h2.8v-2.8zm0-2.8h2.8v2.799h-2.8v-2.8z"
				clipRule="evenodd"
			></path>
			<path
				fill="url(#paint7_linear_34_83)"
				d="M58.376 48.854l1.315-7.118a1.4 1.4 0 00-1.12-1.631l-6.916-1.281-.511 2.753 3.703.685L37.98 54.465l1.64 2.27 16.683-12.07-.68 3.68 2.753.51z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_34_83"
					x1="22.001"
					x2="22.001"
					y1="41.599"
					y2="91.999"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#DEE9FD" stopOpacity="0.26"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_34_83"
					x1="73.8"
					x2="73.8"
					y1="8.001"
					y2="44.136"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F39244"></stop>
					<stop offset="1" stopColor="#F8CCA9" stopOpacity="0.4"></stop>
				</linearGradient>
				<linearGradient
					id="paint2_linear_34_83"
					x1="15.001"
					x2="15.001"
					y1="10.801"
					y2="13.601"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#DEE9FD"></stop>
				</linearGradient>
				<linearGradient
					id="paint3_linear_34_83"
					x1="36"
					x2="36"
					y1="26.201"
					y2="29"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#DEE9FD"></stop>
				</linearGradient>
				<linearGradient
					id="paint4_linear_34_83"
					x1="90.599"
					x2="90.599"
					y1="86.399"
					y2="89.199"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#DEE9FD"></stop>
				</linearGradient>
				<linearGradient
					id="paint5_linear_34_83"
					x1="69.599"
					x2="69.599"
					y1="64"
					y2="66.8"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#DEE9FD"></stop>
				</linearGradient>
				<linearGradient
					id="paint6_linear_34_83"
					x1="50"
					x2="50"
					y1="79.399"
					y2="82.199"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#DEE9FD"></stop>
				</linearGradient>
				<linearGradient
					id="paint7_linear_34_83"
					x1="39.5"
					x2="60"
					y1="54"
					y2="40"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7BC598"></stop>
					<stop offset="1" stopColor="#9CF1BE" stopOpacity="0.49"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
