import { PrimaryBtn } from "components/library"
import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

export const RequestADemoPrimaryCta = () => {
	const { t } = useTranslation()

	return (
		<PrimaryBtn onClick={() => window.requestADemo()}>
			{t("common.cta.demo")}
		</PrimaryBtn>
	)
}
