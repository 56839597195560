import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const Pattern4Illustration: Illustration = ({
	className = "",
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 70 76"
			className={mergeClasses({ classes: ["w-40", className] })}
			{...props}
		>
			<path
				fill="url(#paint0_linear_6564_41122)"
				fillRule="evenodd"
				d="M53.875 21.438c0 10.182-8.255 18.437-18.438 18.437C25.255 39.875 17 31.62 17 21.437 17 11.255 25.255 3 35.437 3c10.183 0 18.438 8.255 18.438 18.438zm3 0c0 11.84-9.598 21.437-21.438 21.437C23.597 42.875 14 33.277 14 21.437 14 9.598 23.598 0 35.437 0c11.84 0 21.438 9.598 21.438 21.438zm7.875 8.312A5.256 5.256 0 0059.5 35v9.593l-1.878 1.324a5.254 5.254 0 00-8.215-1.094l-9.372 8.918a5.206 5.206 0 00-1.535 3.705V73.5a1.748 1.748 0 001.75 1.75h10.5a1.748 1.748 0 001.75-1.75v-4.474l15.608-14.129A5.254 5.254 0 0070 50.854V35a5.256 5.256 0 00-5.25-5.25zm1.75 21.104a1.807 1.807 0 01-.687 1.402L49.576 66.953A1.75 1.75 0 0049 68.25v3.5h-7V57.446a1.706 1.706 0 01.478-1.2l9.372-8.916a1.751 1.751 0 012.511 2.44l-6.764 6.394A1.75 1.75 0 1050 58.707l6.8-6.428a5.199 5.199 0 001.39-2.48l4.068-2.869A1.752 1.752 0 0063 45.5V35a1.75 1.75 0 113.5 0v15.854zm-45.876-6.002a5.256 5.256 0 00-8.246 1.065L10.5 44.592V35A5.25 5.25 0 100 35v15.854a5.207 5.207 0 001.839 3.996L17.5 69.026V73.5a1.748 1.748 0 001.75 1.75h10.5a1.748 1.748 0 001.75-1.75V57.446a5.234 5.234 0 00-1.566-3.736l-9.31-8.858zM28 71.75h-7v-3.5a1.747 1.747 0 00-.576-1.297L4.134 52.208a1.754 1.754 0 01-.634-1.354V35A1.75 1.75 0 117 35v10.5a1.75 1.75 0 00.742 1.43l4.077 2.877c.233.95.723 1.817 1.417 2.507L20 58.707a1.749 1.749 0 102.403-2.543l-6.728-6.359a1.751 1.751 0 112.505-2.446l9.31 8.856a1.73 1.73 0 01.51 1.23V71.75z"
				clipRule="evenodd"
			></path>
			<path
				stroke="url(#paint1_linear_6564_41122)"
				strokeWidth="3"
				d="M55.375 21.716c0 .474-.26 1.12-1.163 1.897-.898.772-2.284 1.535-4.129 2.205-3.676 1.337-8.854 2.193-14.646 2.193-5.791 0-10.97-.856-14.646-2.193-1.844-.67-3.23-1.433-4.128-2.205-.903-.776-1.163-1.422-1.163-1.897 0-.475.26-1.121 1.163-1.897.897-.772 2.284-1.535 4.128-2.206 3.677-1.337 8.854-2.193 14.646-2.193 5.792 0 10.97.856 14.646 2.193 1.845.67 3.232 1.434 4.13 2.206.902.776 1.162 1.422 1.162 1.897z"
			></path>
			<path
				stroke="url(#paint2_linear_6564_41122)"
				strokeWidth="3"
				d="M35.437 41.654c-.474 0-1.12-.26-1.897-1.163-.771-.898-1.535-2.285-2.205-4.129-1.337-3.676-2.193-8.854-2.193-14.646 0-5.792.856-10.97 2.193-14.646.67-1.844 1.434-3.231 2.205-4.129.776-.903 1.423-1.162 1.897-1.162.475 0 1.121.26 1.897 1.162.772.898 1.535 2.285 2.206 4.129 1.337 3.676 2.193 8.854 2.193 14.646 0 5.792-.856 10.97-2.193 14.646-.67 1.844-1.434 3.231-2.206 4.129-.776.903-1.422 1.163-1.897 1.163z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_6564_41122"
					x1="35"
					x2="35"
					y1="0"
					y2="75.25"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#3C67F0"></stop>
					<stop offset="1" stopColor="#DEE9FD" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_6564_41122"
					x1="35.437"
					x2="35.437"
					y1="13.92"
					y2="29.511"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F39244"></stop>
					<stop offset="1" stopColor="#F8CCA9" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint2_linear_6564_41122"
					x1="38.5"
					x2="35.716"
					y1="6.682"
					y2="42.597"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7BC598"></stop>
					<stop offset="1" stopColor="#9CF1BE" stopOpacity="0"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
